import Title from "../../components/Title";
import Underline from "../../assets/images/underline.svg";
import { Fade } from "react-reveal";
export default function About() {
  return (
    <div className="">
      <Title title={"About Us"} />
      <div className="container mx-auto px-8 py-20">
        <Fade top>
          <p className="text-center uppercase text-3xl">Who We Are</p>
        </Fade>
        <img src={Underline} alt={Underline} className="w-60 mt-1 mx-auto" />
        <div className="mt-10 lg:grid grid-cols-3 gap-10 text-gray-500">
          <Fade left>
            <img
              className="object-cover h-full w-full"
              src="https://images.pexels.com/photos/245208/pexels-photo-245208.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="about"
            />
          </Fade>
          <Fade right>
            <div className="col-span-2 mt-5 lg:my-auto">
              <p className="leading-7 indent-10 text-justify">
                <span className="text-gray-900">Yesor-decors</span> aspires to
                create a space that people haven`t experienced before and if it
                stays with them or gives them a dream for the future, that`s the
                kind of structure we seek to create. By continuously improving
                and setting new benchmarks in the quality of its services and
                support, Yesor Decors has been successfully meeting customer
                expectations in an environment that is rapidly changing and
                expanding. Our project includes renovations and residential
                interiors as well as commercial interiors.
              </p>
              <p className="leading-7 pt-3 indent-10 text-justify">
                We have been in this interior field for more than 25 years,
                where the company includes an accomplished range of experienced
                employees in the same field.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
