import { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import LogoLight from "../assets/images/logo.png";

import { Link, useLocation } from "react-router-dom";
import { Fade } from "react-reveal";
import Social from "./Social";
export default function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);
  const { pathname } = useLocation();
  const menuClass = "cursor-pointer";
  const mobileMenuClass =
    "cursor-pointer py-2 font-bold border-t border-white text-white";
  useEffect(() => {
    window.window.addEventListener("scroll", (e) => {
      if (window.scrollY > 200) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  });
  return (
    <nav
      className={`w-full duration-700 fixed z-50 top-0 right-0 ${
        scroll ? "bg-white text-black shadow-lg h-20" : "text-white h-28"
      }`}
    >
      <div
        className={`md:container mx-5 md:mx-auto flex justify-between ${
          scroll ? "h-24" : "h-24 sm:h-28"
        }`}
      >
        <div className="my-auto flex">
          {/* Yesor-Decors */}
          <img
            className={`rounded-lg duration-700 ${
              scroll ? "h-16 mb-4" : "hidden"
            }`}
            src={Logo}
            alt=""
          />
          <img
            className={`rounded-lg duration-700 ${
              scroll ? "hidden" : " h-14 my-2 sm:h-24"
            }`}
            src={LogoLight}
            alt=""
          />
          {/* logo */}
        </div>
        <div className="my-auto">
          <svg
            onClick={() => setToggle(!toggle)}
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            className={`${
              toggle ? "block" : ""
            } my-auto iconify iconify--ri md:hidden`}
            width="32"
            height="32"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              className={toggle ? "hidden scale-x-75 duration-700" : "block "}
              fill="currentColor"
              d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"
            ></path>

            <path
              className={toggle ? "block" : "hidden "}
              fill="currentColor"
              d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z"
            ></path>
          </svg>
          <div className={`md:flex  gap-10 md:justify-between my-auto hidden`}>
            <div
              className={`${menuClass} ${
                pathname === "/" ? "border-b-4 border-green-500" : ""
              }`}
            >
              <Link to={"/"}> Home</Link>
            </div>
            <div
              className={`${menuClass} ${
                pathname === "/about" ? "border-b-4 border-green-500" : ""
              }`}
            >
              <Link to={"/about"}> About</Link>
            </div>
            <div
              className={`${menuClass} ${
                pathname === "/services" ? "border-b-4 border-green-500" : ""
              }`}
            >
              <Link to={"/services"}>Services</Link>
            </div>
            {/* <div
              className={`${menuClass} ${
                pathname === "/portfolio" ? "border-b-4 border-green-500" : ""
              }`}
            >
              <Link to={"/portfolio"}>Portfolio</Link>
            </div> */}
            <div
              className={`${menuClass} ${
                pathname === "/contact" ? "border-b-4 border-green-500" : ""
              }`}
            >
              <Link to={"/contact"}>Contact</Link>
            </div>
          </div>
        </div>
      </div>
      <Fade>
        <div
          className={`${
            toggle ? "block md:hidden" : "hidden"
          } mx-5 bg-green-500 px-5 py-8 rounded-lg mt-5`}
        >
          <div className={`gap-10 md:justify-between my-auto`}>
            <div className={`${mobileMenuClass}`}>
              <Link onClick={() => setToggle(false)} className="pl-2" to={"/"}>
                {" "}
                Home
              </Link>
            </div>
            <div className={`${mobileMenuClass}`}>
              <Link
                onClick={() => setToggle(false)}
                className="pl-2"
                to={"/about"}
              >
                {" "}
                About
              </Link>
            </div>
            <div className={mobileMenuClass}>
              <Link
                onClick={() => setToggle(false)}
                className="pl-2"
                to={"/services"}
              >
                {" "}
                Services
              </Link>
            </div>
            <div className={mobileMenuClass}>
              <Link
                onClick={() => setToggle(false)}
                className="pl-2"
                to={"/portfolio"}
              >
                {" "}
                Portfolio
              </Link>
            </div>
            <div className={mobileMenuClass + " border-b"}>
              <Link
                onClick={() => setToggle(false)}
                className="pl-2"
                to={"/contact"}
              >
                {" "}
                Contact
              </Link>
            </div>
            <div className={mobileMenuClass + " border-t-0 mt-4 w-64 mx-auto"}>
              <Social justifyBetween={true} />
            </div>
          </div>
        </div>
      </Fade>
    </nav>
  );
}
