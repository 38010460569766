import ServicesList from "../components/ServicePage/Services";
import Title from "../components/Title";

export default function Services() {
  return (
    <div className="xl:mb-96">
      <Title title={"Services"} />
      <ServicesList />
    </div>
  );
}
