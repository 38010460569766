export default function ServicesCard({ service }) {
  return (
    <div className="w-full h-full">
      <img
        src={service.img}
        alt=""
        className="h-72 object-cover w-full transform transition"
      />
      <div className="bg-gray-100 p-5">
        <p className="text-center uppercase pb-3 font-medium text-green-500 text-xl">
          {service.title}
        </p>
        <p className="text-gray-600 text-center h-40">{service.desc}</p>
      </div>
    </div>
  );
}
