import About from "../components/AboutPage/about";
import InteriorSolution from "../components/AboutPage/interiorSolutions";
import Process from "../components/AboutPage/process";

export default function Home() {
  return (
    <div className="xl:mb-96">
      <About />
      <Process />
      <InteriorSolution />
    </div>
  );
}
