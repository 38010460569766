import { Zoom, Fade } from "react-reveal";
import Underline from "../../assets/images/underline.svg";
import QA from "../../assets/images/qa.svg";
import HA from "../../assets/images/home.svg";
import EE from "../../assets/images/endtoend.svg";
import BF from "../../assets/images/budget.svg";

export default function WhyGSH() {
  const reasons = [
    {
      title: "Quality assurance",
      desc: "No Compromise on quality",
      img: QA,
    },
    {
      title: "Complete home aminitines",
      desc: "Comfort in your style",
      img: HA,
    },
    {
      title: "END-TO-END Solutions",
      desc: "On time delivery",
      img: EE,
    },
    {
      title: "Budget Friendly",
      desc: "Easily affordable",
      img: BF,
    },
  ];
  return (
    <div className="container mx-auto px-8 py-16">
      <Zoom>
        <h1 className="text-xl text-center uppercase text-green-500 font-bold">
          Why Work With
        </h1>
      </Zoom>
      <Fade top>
        <p className=" text-3xl mt-7 text-center">Yesor-decors</p>
      </Fade>
      <img src={Underline} alt={Underline} className="w-60 mt-1 mx-auto" />
      <div className="my-16 sm:grid xl:grid-cols-4 grid-cols-2 gap-10">
        {reasons.map((reason, i) => {
          return (
            <Zoom key={i}>
              <div className="p-5 rounded-lg h-72 hover:border-b-8 hover:border-green-500 hover:shadow-2xl shadow-md duration-700">
                <div className="h-40 flex">
                  <img
                    src={reason.img}
                    className="h-28 m-auto"
                    alt={reason.title}
                  />
                </div>
                <div>
                  <p className="text-green-500 text-center mt-4 font-bold">
                    {reason.title}
                  </p>
                  <p className="text-gray-500 text-center mt-2">
                    {reason.desc}
                  </p>
                </div>
              </div>
            </Zoom>
          );
        })}
      </div>
    </div>
  );
}
