import ContactInfo from "../components/ContactPage/contactInfo";
import EnquiryForm from "../components/ContactPage/enquiryForm";
import Title from "../components/Title";

export default function Contact() {
  return (
    <div className="xl:mb-96">
      <Title title={"Contact Info"} />
      <ContactInfo />
      <EnquiryForm />
    </div>
  );
}
