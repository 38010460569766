export default function ServiceCard({ service }) {
  const { title, img, desc } = service;
  return (
    <div className="md:grid grid-cols-7 2xl:mt-0 mt-8 border bg-white">
      <div className="col-span-3">
        <img src={img} className="object-cover h-64 w-full" alt={img} />
      </div>
      <div className="col-span-4 p-5 my-auto">
        <p className="sm:text-lg text-md mb-3 font-bold uppercase">{title}</p>
        <ul className="text-gray-500 sm:text-md">
          {desc.map((e, i) => {
            return (
              <li className="pb-1 li" key={i}>
                <span>{e}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
