import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Fade } from "react-reveal";
import Typewriter from "typewriter-effect";

export default function Banner() {
  return (
    <div className="relative backdrop-brightness-50 bg-black/5">
      <div
        id="mydiv"
        className="bg-no-repeat bg-cover bg-center overflow-x-hidden"
      ></div>
      <div className=" -mt-20 sm:mt-0 rounded-xl p-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Fade top cascade>
          <div
            style={{ width: "820px" }}
            className="md:text-6xl mx-4 text-3xl sm:text-4xl text-center font-bold text-white"
          >
            Welcome to Yesor Decors
          </div>
        </Fade>
        <div
          style={{ width: "470px" }}
          className="text-xl md:text-3xl mx-auto mt-5 text-center px-20 md:px-0 text-white banner-text"
        >
          <Typewriter
            options={{
              strings: [
                "Your home should tell the story of who you are, and be a collection of what you love.",
              ],
              autoStart: true,
              loop: true,
              pauseFor: 10000,
            }}
          />
        </div>
      </div>
    </div>
  );
}
