import Underline from "../../assets/images/underline.svg";
import Sample2 from "../../assets/images/sample1.jpg";
import Sample1 from "../../assets/images/sample2.jpg";
import { Fade, Zoom } from "react-reveal";
import ServiceCard from "./ServiceCard";

export default function OurServices() {
  const services = [
    {
      title: "Interiors for residental spaces",
      img: Sample2,
      desc: [
        "Modular kitchen cabinets and accessories.",
        "Wardrobe with loft cabinets and dressing table.",
        "False ceiling and T.V. Cabinets.",
        "Pooja cabinets and study tables.",
        "Dining crockery units and wall niches.",
        "Wooden partitions and shower screens.",
      ],
    },
    {
      title: "INTERIORS FOR COMMERCIAL SPACES",
      img: Sample1,
      desc: [
        "Workstation cabins and accessories.",
        "Wooden partitions and False ceiling.",
        "Reception Tables and M.D tables.",
        "Workstation chairs and Executive chairs.",
        "Complete wood, gypsum or glass partition.",
        "Portable storage cabinets.",
      ],
    },
  ];
  return (
    <div className="bg-[url('./assets/images/service.jpg')] bg-cover bg-center bg-fixed">
      <div className=" backdrop-brightness-50 bg-black/10">
        <div className="container mx-auto px-8 py-16 overflow-x-hidden">
          <div>
            <Zoom>
              <h1 className="text-xl text-center uppercase text-green-500 font-bold">
                Our Services
              </h1>
            </Zoom>
            <Fade top>
              <p className="text-center uppercase text-3xl mt-7 text-white">
                Who We Are
              </p>
            </Fade>
            <img
              src={Underline}
              alt={Underline}
              className="w-60 mt-1 mx-auto"
            />
            <div className="mt-16 2xl:w-full text-sm w-full lg:w-4/5 mx-auto xl:w-2/3 2xl:grid gap-20 grid-cols-2">
              <Fade left>
                <ServiceCard service={services[0]} />
              </Fade>
              <Fade right>
                <ServiceCard service={services[1]} />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
