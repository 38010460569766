import { Link } from "react-router-dom";
import FB from "../assets/images/fb.svg";
import Insta from "../assets/images/insta.svg";
import WA from "../assets/images/wa.svg";
import Web from "../assets/images/web.svg";

export default function Social({ justifyBetween }) {
  return (
    <div
      className={`flex select-none mt-auto ${
        justifyBetween ? "justify-between" : "gap-x-5"
      }`}
    >
      <a
        // href="https://www.facebook.com/gshomesofficial/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={FB} alt="fb" />
      </a>
      <a
        // href="https://instagram.com/_gshomes_?igshid=YmMyMTA2M2Y="
        target="_blank"
        rel="noreferrer"
      >
        <img src={Insta} alt="insta" />
      </a>
      <a
        // href="https://api.whatsapp.com/send?phone=9677110646"
        target="_blank"
        rel="noreferrer"
      >
        <img src={WA} alt="wa" />
      </a>
      <Link to={"/"}>
        <img src={Web} alt="web" />
      </Link>
    </div>
  );
}
