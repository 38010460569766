import Underline from "../../assets/images/underline.svg";
import { Fade, Zoom } from "react-reveal";

export default function Process() {
  const processes = [
    ["MEET", "&", "AGREE"],
    ["IDEA", "&", "CONCEPT"],
    ["DESIGN", "&", "CREATE"],
    ["BUILD", "&", "INSTALL"],
  ];
  return (
    <div className="bg-[url('./assets/images/service.jpg')] bg-cover bg-center bg-fixed">
      <div className="backdrop-brightness-50 bg-black/10  py-20">
        <div className="container mx-auto px-8 py-10">
          <Fade top>
            <p className="text-center uppercase text-3xl text-white">PROCESS</p>
          </Fade>{" "}
          <img src={Underline} alt={Underline} className="w-60 mt-1 mx-auto" />
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-20 mt-16">
            {processes.map((process) => {
              return (
                <Zoom>
                  <div
                    key={process}
                    className="h-52 w-52 xl:h-60 xl:w-60 flex mx-auto bg-orange-400/80"
                    style={{ clipPath: "circle(50.0% at 50% 50%)" }}
                  >
                    <p className="m-auto font-bold text-3xl text-white text-center">
                      {process[0]} <br /> {process[1]} <br /> {process[2]}
                    </p>
                  </div>
                </Zoom>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
// gallery
// port
