import Underline from "../../assets/images/underline.svg";
import { Fade, Zoom } from "react-reveal";

export default function ContactInfo() {
  return (
    <div className="container mx-auto px-8 py-16">
      <div className=""></div>
      <Fade top>
        <p className="text-center uppercase text-3xl mt-5 mb-1 text-black">
          Contact US
        </p>
      </Fade>
      <img src={Underline} alt={Underline} className="w-60 mt-1 mx-auto" />
      <Fade>
        <div className="mt-16 grid lg:grid-cols-3">
          <div className="flex items-center gap-8">
            <div className="w-14 h-14 flex items-center rounded-full border-2 border-green-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                ariaHidden="true"
                role="img"
                class="iconify mx-auto iconify--gridicons"
                width="40"
                height="40"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#22c55e"
                  d="M19 9A7 7 0 1 0 5 9c0 1.387.409 2.677 1.105 3.765h-.008L12 22l5.903-9.235h-.007A6.971 6.971 0 0 0 19 9zm-7 3a3 3 0 1 1 0-6a3 3 0 0 1 0 6z"
                ></path>
              </svg>
            </div>
            <p>
              No 2, Gopi naidu street,
              <br className="lg:block" /> Thiruverkadu main road,
              <br /> Ayapakkam,
              <br />
              Chennai - 600 077.
            </p>
          </div>
          <div className="flex items-center gap-8 lg:mx-auto mt-5 lg:mt-0">
            <div className="w-14 h-14 flex items-center rounded-full border-2 border-green-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                ariaHidden="true"
                role="img"
                class="iconify mx-auto iconify--material-symbols"
                width="40"
                height="40"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#22c55e"
                  d="M19.95 21q-3.225 0-6.287-1.438q-3.063-1.437-5.425-3.8q-2.363-2.362-3.8-5.425Q3 7.275 3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.225t.325.575l.65 3.5q.05.35-.012.637q-.063.288-.288.513L7 10.9q1.05 1.8 2.625 3.375T13.1 17l2.35-2.35q.225-.225.588-.338q.362-.112.712-.062l3.45.7q.35.075.575.337q.225.263.225.613v4.05q0 .45-.3.75t-.75.3Z"
                ></path>
              </svg>
            </div>
            <p>
              <a href="tel:9677110646" target="_blank" rel="noreferrer">
                +91 9677110646
              </a>{" "}
              <a href="tel:9962720646" target="_blank" rel="noreferrer">
                +91 9962720646
              </a>
            </p>
          </div>
          <div className="flex items-center gap-8 lg:ml-auto mt-5 lg:mt-0">
            <div className="w-14 h-14 flex items-center rounded-full border-2 border-green-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                ariaHidden="true"
                role="img"
                class="iconify mx-auto iconify--ic"
                width="40"
                height="40"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#22c55e"
                  d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5l-8-5h16zm0 12H4V8l8 5l8-5v10z"
                ></path>
              </svg>
            </div>
            <div>
              <a
                // href="mailto:gshomes444@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                yesor-decors@gmail.com
              </a>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
