import Call from "../assets/images/call.svg";
import Mail from "../assets/images/mail.svg";
import Typewriter from "typewriter-effect";
import Logo from "../assets/images/logo.png";
import Social from "./Social";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="xl:absolute bottom-0 bg-gray-600 w-full xl:h-96">
      <div className="container mx-auto px-8 py-16">
        <div className="flex justify-between gap-y-10 flex-wrap">
          <div className="mt-5 xl:block hidden">
            {/* logo */}
            <img className="h-20" src={Logo} alt="footer-logo" />
            <a
              href="tel:9677110646"
              target="_blank"
              rel="noreferrer"
              className="flex mt-8"
            >
              <img src={Call} className="rotate-12" alt="call1" />
              <p className="ml-3 text-white">+91 9677110646</p>
            </a>
            <a
              href="tel:9962720646"
              target="_blank"
              rel="noreferrer"
              className="flex mt-3"
            >
              <img src={Call} className="rotate-12" alt="call2" />
              <p className="ml-3 text-white">+91 9962720646</p>
            </a>
            <a
              href="mailto:yesor-decors@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="flex mt-4"
            >
              <img src={Mail} alt="mail" />
              <p className="ml-3 text-white">yesor-decors@gmail.com</p>
            </a>
          </div>
          <div className="w-[28rem]">
            <div className="-mt-10 text-green-500 font-bold text-xl sm:text-4xl">
              <Typewriter
                options={{
                  strings: ["GET IN TOUCH WITH US."],
                  autoStart: true,
                  loop: true,
                  pauseFor: 1000,
                }}
              />
            </div>
            <div className="text-xl mt-4 pb-2 sm:w-1/2 text-white font-bold uppercase border-b-2 border-green-500">
              Quick link
            </div>
            <div className="mt-5 text-white">
              <ul>
                <li id="li">
                  <Link to={"/"}> &nbsp; Home </Link>
                </li>
                <li id="li">
                  <Link to={"/about"}> &nbsp; About </Link>
                </li>
                <li id="li">
                  <Link to={"/services"}> &nbsp; Services </Link>
                </li>
                <li id="li">
                  <Link to={"/portfolio"}> &nbsp; Portfolio </Link>
                </li>
                <li id="li">
                  <Link to={"/contact"}> &nbsp; Contact </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-5 xl:hidden">
            {/* <img className="h-20" src={Logo} alt="footer-logo" /> */}
            <a
              href="tel:9677110646"
              target="_blank"
              rel="noreferrer"
              className="flex mt-8"
            >
              <img src={Call} className="rotate-12" alt="call1" />
              <p className="ml-3 text-white">+91 9677110646</p>
            </a>
            <a
              href="tel:9962720646"
              target="_blank"
              rel="noreferrer"
              className="flex mt-3"
            >
              <img src={Call} className="rotate-12" alt="call2" />
              <p className="ml-3 text-white">+91 9962720646</p>
            </a>
            <a
              href="mailto:yesor-decors@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="flex mt-4"
            >
              <img src={Mail} alt="mail" />
              <p className="ml-3 text-white">yesor-decors@gmail.com</p>
            </a>
          </div>
          <div className="h-full">
            <div className="text-xl mt-4 pb-2 text-white font-bold uppercase border-b-2 border-green-500">
              Contact US
            </div>
            <div className="mt-5 text-white">
              No 2, Gopi naidu street,
              <br /> Thiruverkadu main road,
              <br /> Ayapakkam,
              <br />
              Chennai - 600 077
              <div className="flex h-full mt-8">
                <Social justifyBetween={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
