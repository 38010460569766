import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import About from './pages/about';
import Footer from './components/Footer';
import GoToTop from './components/GoToTop';
import ScrollToTop from "react-scroll-to-top"
import TopArrow from './components/TopArrow';
import WhatsApp from './components/WhatsApp';
import Portfolio from './pages/portfolio';
import Services from './pages/services';
import Contact from './pages/contact';

function App() {
  return (
    <BrowserRouter>
    <div className="App relative overflow-x-hidden">
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        {/* <Route exact path='/portfolio' element={<Portfolio />} /> */}
        <Route exact path='/services' element={<Services />} />
        <Route exact path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
      <GoToTop />
      <ScrollToTop smooth style={{ background: "#22c55e"}} component={<TopArrow/>} />
      <WhatsApp />
    </div>
    </BrowserRouter>
  );
}

export default App;
