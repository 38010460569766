import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Underline from "../../assets/images/underline.svg";
import { Fade } from "react-reveal";

export default function Testimonial() {
  const settings = {
    showArrows: false,
    interval: 3500,
    dynamicHeight: false,
    stopOnHover: true,
    infiniteLoop: true,
    showStatus: false,
    transitionTime: 500,
    showThumbs: false,
    showIndicators: false,
    swipeable: true,
    emulateTouch: true,
    autoPlay: true,
  };
  const testimonials = [
    {
      name: "Shakthi Palavesh",
      testimonial:
        "The way you handled the project showed resilience, experience, knowledge, and a good designing skill. You delivered the best result at the finishing stage of the project within a short span o time. We could love to get your perspective on our next project.",
    },
    {
      name: "Mr. Senthil",
      testimonial:
        "Its really great job, all works are done at a nice way and with clear manner. Apart from all, the approach shown from your end is highly appreciated, as all the clients are expecting like this, understanding their requirement and trying to achieve the same as much as possible is really excellent.",
    },
  ];
  return (
    <div className="container mx-auto px-8 py-16">
      <Fade top>
        <p className="text-center uppercase text-3xl mt-7 text-black">
          Testimonial
        </p>
      </Fade>
      <img src={Underline} alt={Underline} className="w-60 mt-1 mx-auto" />
      <Carousel {...settings} useKeyboardArrows>
        {testimonials.map((testimonial) => {
          return (
            <div className="cursor-wait">
              <div className="h-96 flex">
                <p className="m-auto w-96">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--bx"
                    width="32"
                    height="32"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 6.925 10H10a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2H3a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 17.925 10H21a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <p className="mx-10 -my-1 italic text-green-500">
                    {testimonial.testimonial}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--bx ml-auto"
                    width="32"
                    height="32"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </p>
              </div>
              <p className="mt-auto">- {testimonial.name}</p>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
