import Underline from "../../assets/images/underline.svg";
import { Fade, Zoom } from "react-reveal";
import ServicesCard from "./ServiceCard";
import commercial from "../../assets/images/commercial1.jpg";
import Bedroom from "../../assets/images/bedroom4.jpg";
import Kitchen from "../../assets/images/kitchen1.jpg";
import Living from "../../assets/images/living1.jpg";
import Design from "../../assets/images/designed1.jpg";

export default function ServicesList() {
  const services = [
    {
      title: "Commercial Design",
      desc: "Commercial interior design includes a broad spectrum of interior commercial spaces and environments, including offices, retail stores, restaurants, and other spaces where business is conducted.",
      img: commercial,
    },
    {
      title: "Modular Bedroom Design",
      desc: "Creating the bedroom interior in the modern style, it is possible to combine not only different styles, but also to mix materials: wood and ceramics, bronze and decorative mosaic.",
      img: Bedroom,
    },
    {
      title: "Modular Kitchen Design",
      desc: "A modular kitchen refers to modern kitchen furniture that has been constructed in modules or units. These consist of cabinets with selected elements of standard sizes. A modular kitchen is usually a combination of wall units and base units.",
      img: Kitchen,
    },
    {
      title: "Living Room Design",
      desc: "A Living room is a room in a residence used for the common social activities of the occupants. A typical Living room may contain furnishings such as a sofa, chairs, occasional tables, and bookshelves, electric lamps, rugs, or other furniture.",
      img: Living,
    },
    {
      title: "DESIGNED & ACTUAL",
      desc: "Design means to create a plan or sketch of something that is going to be made later, especially a plan that details what the finished thing will do and look like. A design is the plan or sketch created as a result of this activity.",
      img: Design,
    },
  ];
  return (
    <div className="container mx-auto px-8 py-16">
      <Fade top>
        <p className="text-center uppercase text-3xl mt-5 mb-1 text-black">
          Services
        </p>
      </Fade>
      <img src={Underline} alt={Underline} className="w-60 mt-1 mx-auto" />
      <div className="grid mt-16 md:grid-cols-2 xl:grid-cols-3 gap-5">
        {services.map((service) => {
          return (
            <Zoom>
              <ServicesCard service={service} />
            </Zoom>
          );
        })}
      </div>
    </div>
  );
}
