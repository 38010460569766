import WhatsAppIcon from "../assets/images/whatsapp.svg";

export default function WhatsApp() {
  return (
    <div
      style={{ zIndex: "100" }}
      className="sm:fixed hidden bottom-7 left-8 h-16 w-16"
    >
      <a href="https://api.whatsapp.com/send?phone=9677110646">
        <img src={WhatsAppIcon} alt="whatsapp" />
      </a>
    </div>
  );
}
