import { Fade, Zoom } from "react-reveal";
import Underline from "../../assets/images/underline.svg";
import Pooja from "../../assets/images/pooja.svg";
import Kitchen from "../../assets/images/kitchen.svg";
import Ceiling from "../../assets/images/ceiling.svg";
import Table from "../../assets/images/dining-table.svg";
import Tv from "../../assets/images/living-tv.svg";
import Paint from "../../assets/images/painting.svg";
import Wardrobe from "../../assets/images/wardrobe.svg";

export default function InteriorSolution() {
  const solutions = [
    {
      name: "MODULAR KITCHEN",
      img: Kitchen,
    },
    {
      name: "LIVING & TV UNIT",
      img: Tv,
    },
    {
      name: "POOJA UNIT",
      img: Pooja,
    },
    {
      name: "WARDROBE",
      img: Wardrobe,
    },
    {
      name: "FURNITURE",
      img: Table,
    },
    {
      name: "PAINTINGS",
      img: Paint,
    },
    {
      name: "FALSE CEILING",
      img: Ceiling,
    },
  ];
  return (
    <div>
      <div className="container mx-auto px-8 py-20">
        <Fade top>
          <p className="text-center uppercase text-3xl ">Interior solutions</p>
        </Fade>
        <img src={Underline} alt={Underline} className="w-60 mt-1 mx-auto" />
        <div className="mt-20 flex justify-center gap-20 flex-wrap">
          {solutions.map((solution) => {
            return (
              <Zoom key={solution.name}>
                <div className="w-80 h-72 rounded-lg hover:border-b-8 hover:border-green-500 hover:shadow-2xl shadow-md duration-700">
                  <div className="h-40 flex">
                    <img
                      src={solution.img}
                      className="m-auto mb-0 w-32 h-32"
                      alt=""
                    />
                  </div>
                  <div className="flex h-32">
                    <p className="text-gray-500 font-bold m-auto">
                      {solution.name}
                    </p>
                  </div>
                </div>
              </Zoom>
            );
          })}
        </div>
      </div>
    </div>
  );
}
