import { Zoom } from "react-reveal";
import TitleBg from "../assets/images/title-bg.jpg";

export default function Title({ title }) {
  return (
    <div
      className="bg-center bg-cover"
      style={{
        backgroundImage: `url(
          ${TitleBg}
        )`,
      }}
    >
      <div className="h-72 flex backdrop-brightness-50 bg-white/30">
        <Zoom>
          <div className="m-auto text-white text-6xl">{title}</div>
        </Zoom>
      </div>
    </div>
  );
}
