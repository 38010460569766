import Banner from "../components/HomePage/Banner";
import OurServices from "../components/HomePage/OurServices";
import Testimonial from "../components/HomePage/Testimonial";
import WhyGSH from "../components/HomePage/WhyGSH";

export default function Home() {
  return (
    <div className="xl:mb-96">
      <Banner />
      <WhyGSH />
      <OurServices />
      <Testimonial />
    </div>
  );
}
